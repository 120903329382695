import React from 'react';
import {Layout} from '../components/layout/layout';
import {JoinUs} from '../pages-components/join-us/join-us';

const JoinUsPage = () => (
  <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
    <Layout>
      <JoinUs />
    </Layout>
  </div>
);

export default JoinUsPage;
