import {useStaticQuery, graphql} from 'gatsby';
import React from 'react';
import {mark} from '../../utils/marked';
import {Form} from './form/form';
import st from './join-us.module.scss';
import {Helmet} from 'react-helmet';
import joinImage from './assets/image.svg';

export const JoinUs = () => {
  const {
    restApiJoinUsFormPage: {seo, title, professions, image_text},
  } = query();
  return (
    <>
      <Helmet meta={[{name: 'description', content: seo.description}]}>
        <meta name='robots' content='noindex' />
      </Helmet>
      <div className={st.wrapper}>
        <div className={st.section}>
          <div className={st.form__wrapper}>
            <div className={st.title}>
              <div>Join Our</div>
              <div>Community</div>
            </div>
            <div className={st.subtitle}>Leave Your imprint in IT Forces</div>
            <Form professions={professions} />
          </div>
          <div className={st.image__container} />
          <img className={st.image} src={joinImage} />
        </div>
        <div className={st.section__shadow} />
      </div>
    </>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiJoinUsFormPage {
        seo {
          title
          description
        }
        title
        professions {
          description
          title
          image {
            url
          }
        }
        image_text
        image {
          url
        }
      }
    }
  `);
